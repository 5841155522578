<template>
    <div class="mx-1 md:mx-8 my-8">
        <div class="flex mb-5">
            <h1 class="text-2xl mt-2 ml-2">
                Empresas / {{ $route.params.id ? 'Editar' : 'Novo' }}
            </h1>
        </div>
        
        <div class="mt-5 md:mt-0 md:col-span-2">
            <form action="#" method="POST" autocomplete="off">
                <div class="px-5 pb-4">
                    <div class="grid grid-cols-12 gap-6">
                        <div class="col-span-12 md:col-span-12">
                            <label for="nome" class="block text-sm font-medium">Nome</label>
                            <input v-model="form.nome" type="text" name="nome" id="nome" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                        </div>
                    </div>
                </div>

                <div class="px-5 pb-4">
                    <span class="font-bold">Logo</span> <br/>
                    <img class="mb-5 mt-5 h-16" v-if="form.imagem && form.imagem.filename" :src="`${api}/v1/residuos/doc/${form.imagem.filename}?token=${jwt}&mimetype=${form.imagem.mimetype}&original=${form.imagem.originalname}`" />
                    <vue-dropzone ref="doc" id="doc" :options="dropzoneOptions" v-on:vdropzone-success="updateDoc"></vue-dropzone>
                </div>
                
                <div class="grid grid-cols-2 grap-6">
                    <div class="px-5 py-3 text-left sm:px-6">
                        <router-link :to="`/${route}`" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                            Voltar
                        </router-link>
                    </div>
                    <div class="px-5 py-3 text-right sm:px-6">
                        <button @click="save" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-400">
                            Salvar
                        </button>
                    </div>
                </div>
                
            </form>
        </div>
    </div>
</template>

<script>
import vueDropzone from 'vue2-dropzone';

export default {
    components: {
        vueDropzone,
    },
    data() {
        return {
            route: 'empresas',
            form: {
                nome: '',
                imagem: {
                    originalname: '',
                    filename: '',
                    mimetype: '',
                },
            },
            dropzoneOptions: this.$http.getDropzoneConfig(
                `/residuos/upload`,
                'post',
                {
                    maxFiles: 1,
                    addRemoveLinks: true,
                    capture: true,
                    dictDefaultMessage: 'Clique ou arraste uma imagem aqui para trocar a logo',
                    dictRemoveFile: 'Remover'
                }
            ),
        }
    },
    methods: {
        async save() {
            const method = this.form._id ? 'put' : 'post'

            const req = await this.$http[method](`/v1/${this.route}`, this.form);

            if (req.data.success) {
                this.$vToastify.success("Salvo com sucesso!");
                this.$router.push({path: `/${this.route}`});
            } else {
                this.$vToastify.error(req.data.err);
            }
        },
        updateDoc(file, response) {
            this.form.imagem = response;
            this.$refs.doc.removeFile(file);
        },
    },
    async beforeMount() {
        const id = this.$route.params.id;

        if (id) {
            const req = await this.$http.get(`/v1/${this.route}/${id}`);

            if (!req.data.imagem) {
                req.data.imagem = {
                    originalname: '',
                    filename: '',
                    mimetype: '',
                };
            }

            this.form = req.data;
        }
    },
}
</script>